const _temp0 = require("../img/gallery/reduced/12472_sq.jpg");
const _temp1 = require("../img/gallery/reduced/12877_sq.jpg");
const _temp2 = require("../img/gallery/reduced/13239_sq.jpg");
const _temp3 = require("../img/gallery/reduced/14834_sq.jpg");
const _temp4 = require("../img/gallery/reduced/16239_sq.jpg");
const _temp5 = require("../img/gallery/reduced/18811_h.jpg");
const _temp6 = require("../img/gallery/reduced/19393_h.jpg");
const _temp7 = require("../img/gallery/reduced/20458_sq.jpg");
const _temp8 = require("../img/gallery/reduced/20809_h.jpg");
const _temp9 = require("../img/gallery/reduced/22607_h.jpg");
const _temp10 = require("../img/gallery/reduced/22721_sq.jpg");
const _temp11 = require("../img/gallery/reduced/24791_h.jpg");
const _temp12 = require("../img/gallery/reduced/25420_v.jpg");
const _temp13 = require("../img/gallery/reduced/28207_sq.jpg");
const _temp14 = require("../img/gallery/reduced/28484_sq.jpg");
const _temp15 = require("../img/gallery/reduced/29088_sq.jpg");
const _temp16 = require("../img/gallery/reduced/29800_sq.jpg");
const _temp17 = require("../img/gallery/reduced/29838_sq.jpg");
const _temp18 = require("../img/gallery/reduced/30296_sq.jpg");
const _temp19 = require("../img/gallery/reduced/32359_h.jpg");
const _temp20 = require("../img/gallery/reduced/33376_sq.jpg");
const _temp21 = require("../img/gallery/reduced/33560_h.jpg");
const _temp22 = require("../img/gallery/reduced/34076_sq.jpg");
const _temp23 = require("../img/gallery/reduced/35270_sq.jpg");
const _temp24 = require("../img/gallery/reduced/36116_h.jpg");
const _temp25 = require("../img/gallery/reduced/36910_sq.jpg");
const _temp26 = require("../img/gallery/reduced/38769_h.jpg");
const _temp27 = require("../img/gallery/reduced/42845_sq.jpg");
const _temp28 = require("../img/gallery/reduced/45758_sq.jpg");
const _temp29 = require("../img/gallery/reduced/48671_sq.jpg");
const _temp30 = require("../img/gallery/reduced/52643_v.jpg");
const _temp31 = require("../img/gallery/reduced/56409_h.jpg");
const _temp32 = require("../img/gallery/reduced/56700_h.jpg");
const _temp33 = require("../img/gallery/reduced/57390_h.jpg");
const _temp34 = require("../img/gallery/reduced/60508_h.jpg");
const _temp35 = require("../img/gallery/reduced/61245_sq.jpg");
const _temp36 = require("../img/gallery/reduced/61906_h.jpg");
const _temp37 = require("../img/gallery/reduced/62354_sq.jpg");
const _temp38 = require("../img/gallery/reduced/64695_h.jpg");
const _temp39 = require("../img/gallery/reduced/65210_h.jpg");
const _temp40 = require("../img/gallery/reduced/66360_h.jpg");
const _temp41 = require("../img/gallery/reduced/66401_sq.jpg");
const _temp42 = require("../img/gallery/reduced/66522_h.jpg");
const _temp43 = require("../img/gallery/reduced/67332_sq.jpg");
const _temp44 = require("../img/gallery/reduced/67619_sq.jpg");
const _temp45 = require("../img/gallery/reduced/72040_sq.jpg");
const _temp46 = require("../img/gallery/reduced/72107_h.jpg");
const _temp47 = require("../img/gallery/reduced/73084_sq.jpg");
const _temp48 = require("../img/gallery/reduced/74341_h.jpg");
const _temp49 = require("../img/gallery/reduced/74852_sq.jpg");
const _temp50 = require("../img/gallery/reduced/75107_h.jpg");
const _temp51 = require("../img/gallery/reduced/75137_sq.jpg");
const _temp52 = require("../img/gallery/reduced/75258_h.jpg");
const _temp53 = require("../img/gallery/reduced/77023_sq.jpg");
const _temp54 = require("../img/gallery/reduced/77231_sq.jpg");
const _temp55 = require("../img/gallery/reduced/7728_sq.jpg");
const _temp56 = require("../img/gallery/reduced/77331_h.jpg");
const _temp57 = require("../img/gallery/reduced/78566_sq.jpg");
const _temp58 = require("../img/gallery/reduced/79343_h.jpg");
const _temp59 = require("../img/gallery/reduced/80216_h.jpg");
const _temp60 = require("../img/gallery/reduced/80221_sq.jpg");
const _temp61 = require("../img/gallery/reduced/80248_sq.jpg");
const _temp62 = require("../img/gallery/reduced/80384_sq.jpg");
const _temp63 = require("../img/gallery/reduced/8071_h.jpg");
const _temp64 = require("../img/gallery/reduced/80827_h.jpg");
const _temp65 = require("../img/gallery/reduced/81895_sq.jpg");
const _temp66 = require("../img/gallery/reduced/8206_sq.jpg");
const _temp67 = require("../img/gallery/reduced/84043_sq.jpg");
const _temp68 = require("../img/gallery/reduced/87703_sq.jpg");
const _temp69 = require("../img/gallery/reduced/87809_h.jpg");
const _temp70 = require("../img/gallery/reduced/90891_sq.jpg");
const _temp71 = require("../img/gallery/reduced/92112_v.jpg");
const _temp72 = require("../img/gallery/reduced/92782_sq.jpg");
const _temp73 = require("../img/gallery/reduced/93201_h.jpg");
const _temp74 = require("../img/gallery/reduced/94849_h.jpg");
const _temp75 = require("../img/gallery/reduced/96217_sq.jpg");
const _temp76 = require("../img/gallery/reduced/96537_h.jpg");
const _temp77 = require("../img/gallery/reduced/96816_h.jpg");
const _temp78 = require("../img/gallery/reduced/99889_h.jpg");
module.exports = {
  "12472_sq": _temp0,
  "12877_sq": _temp1,
  "13239_sq": _temp2,
  "14834_sq": _temp3,
  "16239_sq": _temp4,
  "18811_h": _temp5,
  "19393_h": _temp6,
  "20458_sq": _temp7,
  "20809_h": _temp8,
  "22607_h": _temp9,
  "22721_sq": _temp10,
  "24791_h": _temp11,
  "25420_v": _temp12,
  "28207_sq": _temp13,
  "28484_sq": _temp14,
  "29088_sq": _temp15,
  "29800_sq": _temp16,
  "29838_sq": _temp17,
  "30296_sq": _temp18,
  "32359_h": _temp19,
  "33376_sq": _temp20,
  "33560_h": _temp21,
  "34076_sq": _temp22,
  "35270_sq": _temp23,
  "36116_h": _temp24,
  "36910_sq": _temp25,
  "38769_h": _temp26,
  "42845_sq": _temp27,
  "45758_sq": _temp28,
  "48671_sq": _temp29,
  "52643_v": _temp30,
  "56409_h": _temp31,
  "56700_h": _temp32,
  "57390_h": _temp33,
  "60508_h": _temp34,
  "61245_sq": _temp35,
  "61906_h": _temp36,
  "62354_sq": _temp37,
  "64695_h": _temp38,
  "65210_h": _temp39,
  "66360_h": _temp40,
  "66401_sq": _temp41,
  "66522_h": _temp42,
  "67332_sq": _temp43,
  "67619_sq": _temp44,
  "72040_sq": _temp45,
  "72107_h": _temp46,
  "73084_sq": _temp47,
  "74341_h": _temp48,
  "74852_sq": _temp49,
  "75107_h": _temp50,
  "75137_sq": _temp51,
  "75258_h": _temp52,
  "77023_sq": _temp53,
  "77231_sq": _temp54,
  "7728_sq": _temp55,
  "77331_h": _temp56,
  "78566_sq": _temp57,
  "79343_h": _temp58,
  "80216_h": _temp59,
  "80221_sq": _temp60,
  "80248_sq": _temp61,
  "80384_sq": _temp62,
  "8071_h": _temp63,
  "80827_h": _temp64,
  "81895_sq": _temp65,
  "8206_sq": _temp66,
  "84043_sq": _temp67,
  "87703_sq": _temp68,
  "87809_h": _temp69,
  "90891_sq": _temp70,
  "92112_v": _temp71,
  "92782_sq": _temp72,
  "93201_h": _temp73,
  "94849_h": _temp74,
  "96217_sq": _temp75,
  "96537_h": _temp76,
  "96816_h": _temp77,
  "99889_h": _temp78
}