import images from '../img/gallery/reduced/*.jpg';
import Masonry from '../node_modules/masonry-layout';

(() => {

  const { toggleBodyClass } = require('./utils');

  // Handle hobbies button
  const hobbiesButtons = document.getElementsByClassName('evp-hobbies-toggle');
  const closeBtn = document.querySelector('.evp-hobbies__close');
  const galleryLinks = document.querySelectorAll('.evp-hobbies a');
  let visible = false;

  for (let i = 0; i < hobbiesButtons.length; i++) {
    hobbiesButtons[i].addEventListener('click', e => {

      const className = 'evp-t-hobbies';
      toggleBodyClass(className)(e);

      // Init Gallery

      if (document.body.className.indexOf(className) > -1) {

        import('../node_modules/photoswipe/dist/photoswipe-lightbox.esm.js').then((PhotoSwipeLightbox) => {
          const PhotoSwipe = import('../node_modules/photoswipe/dist/photoswipe.esm.js');
          const lightbox = new PhotoSwipeLightbox.default({
            gallery: '#evp-gallery',
            children: 'a',
            pswpModule: () => PhotoSwipe,
          });

          loadImages();
          lightbox.init();

          // Masonry
          let elem = document.getElementById('evp-gallery');
          const msnry = new Masonry( elem, {
            // options
            itemSelector: '#evp-gallery .col',
            // columnWidth: 5
          });

          visible = true;

          // Close button autofocus
          setTimeout(() => closeBtn.focus(), 200);

          closeBtn.addEventListener('click', () => {
            setTimeout(() => document.querySelector('.evp-hobbies__btn').focus(), 200);
          });
        });
      }

      // Init player
      require('./player');

    });

    // Close Gallery by ESC or navigate back Experience
    window.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        if (visible) {
          closeBtn.click();
          visible = false;
        } else {
          history.back();
        }
      }
    });
  }

  // Lazy Loading images

  const loadImages = () => {
    const lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

    if ("IntersectionObserver" in window) {
      let lazyImageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.src = images[ lazyImage.dataset.name ];
            lazyImage.srcset = lazyImage.dataset.srcset || '';
            lazyImage.classList.remove("lazy");
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      lazyImages.forEach((lazyImage) => {
        lazyImageObserver.observe(lazyImage);
      });
    }
  }

})();
