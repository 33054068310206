(() => {

  var YouTubeIframeLoader = require('youtube-iframe');
  let player;
  let intervalID;

  const playerElement = document.querySelector('.evp-player');
  const playingClass = 'evp-player_is-playing';
  const expandedClass = 'evp-player_is-expanded';
  let isPlaying = false;
  let isExpanded = false;

  let timerValue = 0.0;

  // Init Player

  YouTubeIframeLoader.load(function(YT) {
    player = new YT.Player('player', {
      height: '100',
      width: '200',
      playerVars: {
        playsinline: 1,
        // controls: 0,
        listType:'playlist',
        list: 'PLpMMx50tRIhBEKOlqMiH9l8wRXuRH3ofA'
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  });

  /**
   * @param {{}} event
   */
  const onPlayerReady = (event) => {

    refreshPlayerInfo();

    // Handle Play / Pause

    document.querySelector('.evp-player__control_play').addEventListener('click', (e) => {
      if (!isPlaying) {
        player.playVideo();
      } else {
        player.pauseVideo();
      }
    });

    // Handle Expand / Minimize
    document.querySelector('.evp-player__toggle').addEventListener('click', () => {
      if (!isExpanded) {
        playerElement.classList.add(expandedClass);
      } else {
        playerElement.classList.remove(expandedClass);
      }

      isExpanded = !isExpanded;
    });

    document.querySelector('.evp-player__control_prev').addEventListener('click', () => player.previousVideo());
    document.querySelector('.evp-player__control_next').addEventListener('click', () => player.nextVideo());
  }

  /**
   * @param {{}} event
   */
  const onPlayerStateChange = (event) => {
    refreshPlayerInfo(event.data);
  }

  /**
   * @param {Number} playerStatus
   */
  const refreshPlayerInfo = (playerStatus) => {

    if (player.getVideoData().title) {
      document.querySelector('.evp-player__info-title').innerHTML = player.getVideoData().title;
      document.querySelector('.evp-player__info-duration').innerHTML = timeToString(player.getDuration());
    }

    if (1 === playerStatus) {
      isPlaying = true;
      playerElement.classList.add(playingClass);
      runTimer();
    }

    if (2 === playerStatus) {
      isPlaying = false;
      playerElement.classList.remove(playingClass);
      setCurrentTime(player.getCurrentTime());
      stopTimer();
    }
  };

  /**
   * @param {Number} currentTime
   */
  const setCurrentTime = (currentTime) => {
    document.querySelector('.evp-player__info-current-time').innerHTML = timeToString(currentTime);
  };

  /**
   * @param {Number} time
   * @return {string}
   */
  const timeToString = time => new Date(time * 1000).toISOString().slice(11, 19);

  /**
   * @return {void}
   */
  const runTimer = () => {
    timerValue = player.getCurrentTime();
    intervalID = setInterval(() => {
      timerValue = timerValue + 1;
      setCurrentTime(timerValue);
    }, 1000);
  }

  /**
   * @return {void}
   */
  const stopTimer = () => {
    clearInterval(intervalID);
  }

})();
